*{
  margin: 0;
  padding: 0;
}
html, body{
  height: 100%;
}

.wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.titlecontaier{
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e9e284;
  border-bottom: solid 1px black;
  max-height: 40px;
  min-height: 40px;
}
.titleLeft{
  /* display: none; */
}

/* --------------------------------  공지사항부분  -------------------------------- */
.brd-title{
  flex: 1;
  max-height: 40px;
  background: #e9e284;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.brd-container{
  /* flex: 4;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background-color: #f0edc3; */
  flex: 4;
  overflow-y: scroll;
  background-color: #dfddca;
}
.brd-list{
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /* overflow-y:scroll; */
  /* overflow-x: hidden; */
}
/* .brd-list-item{
  display: flex;
  padding: auto;
  font-size: 1rem;
  padding-left: 0.5rem;
  border-bottom: #979582 1px solid;
}
.brd-list-item:hover{
  background: #f0f0ed;
} */
.brd-item-title{
  flex: 8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: -1px;
}
.brd-item-msg{
  flex: 8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: -1px;
}
.brd-item-date{
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;
  letter-spacing: -1px;
  font-size: 0.8rem;
}
.brd-item-del{
  align-items: center;
  padding-right: 0.2rem;
  text-align: center;
  float: right;
  margin-bottom: 0.2rem;
}
.brd-input-container{
  max-height: 100px;
  flex: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background-color: #e9e284;
  border-bottom: solid 1px black;
}
.brd-input-container span{
  flex: 8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem;
  width:100%;
}
.board-input-title{
  border: 0px;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  font-size: 1rem;
  height: 100%;
  padding-left: 0.5rem;
}
.board-input-msg{
  border: 0px;
  border-top: 1px dotted grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px dotted grey;
  font-size: 0.8rem;
  height: 100%;
  padding-left: 0.5rem;
}
.brd-input-file-container{
  background-color:white;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  /* border: solid 1px black; */
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  /* width: 80%; */
  padding: 3px;
  margin: 0px;
}
.board-input-file-msg{
  border: none;
  width: 100%;
  padding: auto;
}
.bsend-button{
  flex: 1;
  background: #ffeb33;
  border: solid 1px #58534e;
  height: 100%;
  margin-left: 5px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

/* -----------------채팅부분 ---------------------------------------- */
.user-container{
  display: flex;
  background: #a9bdce;
  flex: 1;
  max-height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px 3px 0px; 
  
}
.ctitleLeft{
  flex: 3;
  /* background-color: blue; */
}
.ctitleCenter{
  flex: 4;
  display: flex;
  /* background-color: green; */
}
.ctitleRight{
  flex: 3;
  /* background-color: yellow; */
  text-align: right;
  padding-left: 0px;
}
.user-container label{
  flex: 1;
  font-size: 1rem;
}
.user-container input{
  flex: 2;
  border-radius:3px;
  width: 70%;
  border: none;
  padding: 5px;
  /* margin-left: -1.5rem; */
  text-align: left;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;
  -ms-border-radius:3px;
  -o-border-radius:3px;
}

.display-container{
  flex: 12;
  background: #b2c7d9;
  overflow-y: scroll;
}
.input-container{
  flex: 1;
  display: flex;
  max-height: 40px;
  justify-content: stretch;
  align-items: stretch;
  background-color: #a9bdce
}
.input-container span{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
}

.chatting-input{
  font-size: 1rem;
  height: 100%;
  flex: 8;
  border: none;
  padding-left: 0.5rem;

}

.send-button{
  flex: 1;
  background: #ffeb33;
  border: none;
  height: 100%;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.chatting-list li{
  width: 90%;
  padding: 0.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 0.5rem; 
}

.profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0.3rem; */
  background-color: rgb(248, 217, 158);
  border: 1px solid black;
  padding: 0 5px 0 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.user_org{
  font-weight: bold;
  /* background-color: rgb(204, 177, 240); */
  font-size: 12px;
  margin-bottom: -2px;
  padding: 0px;
}
.user{
  /* background-color: aqua; */
  font-size: 12px;
  margin-top: -2px;
  /* padding-bottom: 0px; */
}
.profile .image {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.message{
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 0.3rem;
  font-size: 12px;
  margin: 0 5px;
  flex: 7;
  /* line-height: -5px; */
}

.time{
  font-size: 10px;
  margin:0 5px;
}

.sent{
  flex-direction: row-reverse;
  float: right;
  align-items: baseline;  
}
.sent .message{
  background: #ddf2f5;
}
.received .message{
  background: #fff;
}

 /* ------------  아코디언관련   */
.accordion {
  background-color: #f3f2de;
  color: #333;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  border-bottom: solid 1px #ccc;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}

.active, .accordion:hover {
  background-color: white;
}
.accordion:after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 10px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  font-size: 0.9rem;
  border-bottom: solid 0.5px #5c5a5a;
  transition: max-height 0.2s ease-out;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -ms-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
}