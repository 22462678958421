.main{
  background-color: aliceblue;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.selDs{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: auto;
  background-color: rgb(243, 240, 237);
  align-items: center;
}

.topmenu{
  padding: 5px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  background-color: rgb(238, 119, 22);
  font-weight: bold;
  font-size: 1.5rem;
}

.list{

}
/* .reselecticon{
  font-size: 2rem;
  z-index: 10;
}

.refreshicon{
  z-index: 10;
  font-size: 2.5rem;
  background-color: rgb(252, 202, 168);
} */

/* .title{
  font-size: 1.5rem;
} */

.intitle{
  padding:  5px;
  display: flex;
  justify-content: center;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  background-color: rgb(238, 119, 22);
  font-weight: bold;
  font-size: 1.5rem;
}

.titlerow{
  display: flex;
  /* font-weight: bold; */
  /* font-size: 1.5rem; */
  /* color: white; */
  /* padding: 5px; */
  justify-content: center;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  /* background-color: rgb(238, 119, 22); */
}

.row{
  display: flex;
  justify-content:space-evenly;
  padding: 5px;
  /* border: 5px solid blue; */
  /* border-bottom: 1px solid #ccc; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  /* border-radius: 5px; */
  width: auto;
  background-color: rgb(243, 240, 237);
  align-items: center;
}

.dslistrow{
  display: flex;
  justify-content:space-evenly;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 100%;
  background-color: rgb(243, 240, 237);
  align-items: center;
}

.dslisitem{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  /* height: 4rem; */
  padding: 0.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: larger;
  font-weight: bold;
  border: 5px solid red;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  /* width: 100%; */
  background-color: rgb(231, 217, 217); 
  
}

.label1{
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.3rem;
  font-size: larger;
  font-weight: bold;
  border: 5px solid red;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 30%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.label2{
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.3rem;
  font-size: larger;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 30%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.cnts1{
  display: flex;
  justify-content: center;
  text-align: center;
  height: 2rem;
  padding: 0.3rem;
  font-size: 1.5rem;
  font-weight: bold;
  border: 5px solid red;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 60%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.cnts2{
  height: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0.3rem;
  font-size: 1.5rem;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 60%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn{
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0.3rem;
  font-size: x-large;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 60%;
  background-color: rgb(121, 57, 4);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.btne{
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0.3rem;
  font-size: x-large;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 60%;
  background-color: green;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table{
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem;
  font-size: medium;
  width: 100%;
  border-bottom: 1px solid black;
  overflow: hidden;
}
.table thead{
  background-color: rgb(252, 202, 168);
  padding: 2rem;
  font-weight: bold;
}

.table tbody{
  background-color: rgb(245, 244, 243);
  padding: 2rem;
  font-weight: bold;
}

.table td{
  text-align: center;
  font-weight: bold;
}

.qrcontainer{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #bddbd9;
  border: 2px solid #a80f0f;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  margin: 0.5rem;
  padding: 0.5rem;
}
