.mapctrn{
  position: relative;
  width: 100%;
  height: 300px;
  z-index: 0;
}

.search {
  position: absolute;
  top: 325px;
  padding: 5px;
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
}

.dsmap{
  width: 100%;
  height: 300px;
  z-index: 0;
}