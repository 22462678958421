.g1{
  background-color: rgb(221, 248, 238);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  margin-top: 2px;
}

.g2{
  background-color: cornsilk;
  padding-left: 2px;
  display: flex;
  align-items: center;
  height: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.1px solid rgb(24, 20, 20);
  border-right: 1px solid grey;
}

.g3{
  
  background-color: aquamarine;
  text-align: center;
  vertical-align: middle;
}