.background{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.maincss{
  width: 95%;
  height: 95%;
  background-color: #02312e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.titlebar{
  margin-top: 5px;
  width: 90%;
  /* background-color: lightgreen; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  color: white;
  font-size: 1.2rem;
  font-weight: bolder;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
}
.titledetail{
  font-size: 0.8rem;
  color: white;
  padding: 0 10px 0 10px;
}
.textcnts {
  margin-top: 10px;
  width: 90%;
  height: 30%;
  background-color: white;
  padding: 0 10px 0 10px;
  font-size: 1rem;
  overflow: scroll;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.msgtitle{
  background-color: #26807a;
  color: white;
  margin: -5px 5px 0 -10px;
  padding: 10px 10px 5px 10px;
  z-index: 10000;
  border-radius: 0 0 5px 0;
  -webkit-border-radius: 0 0 5px 0;
  -moz-border-radius: 0 0 5px 0;
  -ms-border-radius: 0 0 5px 0;
  -o-border-radius: 0 0 5px 0;
}
.maptitle{
  position: absolute;
  /* top: 0px;
  left: 0.5%;
  right: 0.5%; */
  /* overflow: hidden;
  width: auto;
  height: auto; */
  margin: -5px 5px 0 -10px;
  padding: 5px 10px 5px 10px;
  z-index: 20;
  background-color: #26807a;
  color: white;
  display: block;
  border-radius: 0 0 5px 0;
  -webkit-border-radius: 0 0 5px 0;
  -moz-border-radius: 0 0 5px 0;
  -ms-border-radius: 0 0 5px 0;
  -o-border-radius: 0 0 5px 0;
}

.dstable tr td{
  border-collapse: collapse;
  border: 1px solid #26807a;
}

.dsmap{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  height: calc(95% - 30px - 30%);
  background-color: white;
  padding: 0 10px 0 10px;
  font-size: 1rem;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dsmain {
  font-weight: bold;
}