.sgnbox{
  display: flex;
  width: 100px;
  height: 60px;
  color: white;
  justify-content: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.sgntxtbox{
  text-align: left;
  letter-spacing: -0.5px;
  font-size: 0.8rem;
  width: 500px;
  height: 60px;
  margin-left: 5px;
  margin-right: 0px;
  padding-left: 5px;
  background-color: bisque;
  justify-content: center;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.black{ background-color: black; }
.green{ background-color: green; }
.blue{ background-color: blue; }
.red{ background-color: red; }
.grey{ background-color: grey; }

@media (prefers-reduced-motion: no-preference) {
  .blackblink {
    animation: black-blinker infinite 2s ;
    -webkit-animation: black-blinker infinite 2s ;
  }
  .greenblink {
    animation: green-blinker infinite 2s ;
    -webkit-animation: green-blinker infinite 2s ;
  }
  .blueblink {
    animation: blue-blinker infinite 2s ;
    -webkit-animation: blue-blinker infinite 2s ;
  }
  .redblink {
    animation: red-blinker infinite 2s ;
    -webkit-animation: red-blinker infinite 2s ;
  }
  .greyblink {
    animation: grey-blinker infinite 2s ;
    -webkit-animation: grey-blinker infinite 2s ;
  }
}

@keyframes black-blinker {
  0%   {background-color:black;}
  25%  {background-color:yellow;}
  50%  {background-color:black;}
  75%  {background-color:yellow;}
  100% {background-color:black;}
}
@keyframes green-blinker {
  0%   {background-color:green;}
  25%  {background-color:yellow;}
  50%  {background-color:green;}
  75%  {background-color:yellow;}
  100% {background-color:green;}
}
@keyframes blue-blinker {
  0%   {background-color:blue;}
  25%  {background-color:yellow;}
  50%  {background-color:blue;}
  75%  {background-color:yellow;}
  100% {background-color:blue;}
}
@keyframes red-blinker {
  0%   {background-color:red;}
  25%  {background-color:yellow;}
  50%  {background-color:red;}
  75%  {background-color:yellow;}
  100% {background-color:red;}
}
@keyframes grey-blinker {
  0%   {background-color:grey;}
  25%  {background-color:yellow;}
  50%  {background-color:grey;}
  75%  {background-color:yellow;}
  100% {background-color:grey;}
}