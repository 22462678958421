.main{
  background-color: #bddbd9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title{
  border-top: 3px solid #a80f0f;
  margin-top: 0.3rem;
  display: flex;
  font-weight: bolder;
  font-size: 1.5rem;
  color: rgb(46, 2, 2);
  /* padding: 5px; */
  margin: 0;
  align-content: center;
  justify-content: center;
  /* 위에서 아래로 그라데이션 추가 */
  background: linear-gradient(to bottom, #3ca79f, #fff);
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  /* background-color: rgb(5, 124, 25); */
  /* border: 1px solid black; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.row{
  display: flex;
  justify-content:space-evenly;
  margin-left: auto;
  margin-right: auto;
  /* padding: 5px; */
  /* border: 1px solid black; */
  /* border-bottom: 1px solid #ccc; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  width: 99%;
  /* background-color: rgb(243, 240, 237); */
  align-items: center;
  margin-bottom: 5px;
}

.tlit{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 2.5rem;
  margin: 2px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 30%;
  line-height: 1;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.icon{
  font-size: 2rem;
}
.subtlit{
  font-size: 0.7rem;
  letter-spacing: -1px;
}

.label1{
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.3rem;
  font-size: larger;
  font-weight: bold;
  border: 5px solid red;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 30%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.label2{
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0.3rem;
  font-size: larger;
  font-weight: bold;
  border: 2px solid blue;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 30%;
  background-color: rgb(231, 217, 217);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn{
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0.3rem;
  font-size: x-large;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  width: 100%;
  background-color: rgb(121, 57, 4);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.infotitlte{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #02312e;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  width: 98%;
  height: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -4px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table{
  /*현재테이블을화면좌우의중앙에정렬*/margin-left: auto;
  margin-right: auto;
  background-color: #02312e;
  font-size: medium;
  width: 98%;
  overflow: scroll;
  margin-bottom: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.table thead{
  background-color: #02312e;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.table tbody{
  background-color: rgb(245, 244, 243);
  padding: 0 0.5rem 0 0.5rem;
  font-weight: bold;
}

.table td{
  text-align: center;
  font-weight: bold;
}

.chat{
  margin-left: auto;
  margin-right: auto;
  width: 98%;
  height: 20rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.rdvscss{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 두번째, 세번째 span 요소에 적용 */
.rdvscss span:nth-child(2), .rdvscss span:nth-child(3){
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0.2rem 0 0.2rem;
  margin-left: 0.5rem;
  border:1px solid black;
  border-radius: 10%;
  -webkit-border-radius:;
  -moz-border-radius:;
  -ms-border-radius:;
  -o-border-radius:;
}
/* 두번째 span요소에 적용 */
.rdvscss span:nth-child(2){
  color: white;
  background-color: blue;
}
/* 세번째 span요소에 적용 */
.rdvscss span:nth-child(3){
  background-color: yellow;
}

.footer{
  /*맨밑에강제위치*/position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
  background-color: #bddbd9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #3ca79f, #fff);
  border-bottom: 3px solid #a80f0f;
  font-weight: 900;
}