.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map {
  width: 99%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: medium;
  border-color: #D8D8D8;
}

.tool{
  position: absolute;
  top: 70px;
  right: 425px;
  width: 160px;
  height: 60px;
  background-color: #97ffa3;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid black;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.tool div{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.tool button{
  width: 48px;
  height: 25px;
  border: 1px solid black;
  background-color: white;
  font-size: 0.8rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  /* 마우스 선택시 배경 반전 */
  



  border-radius: 5px;
  -webkit-border-radius:;
  -moz-border-radius:;
  -ms-border-radius:;
  -o-border-radius:;
}
.tool span{
  padding: 0 5px 0 5px;
  font-size: 0.8rem;
  font-weight: bold;
  width: 40px;
  color: black;
  border: 1px solid black;
}
/* 첫번째 span 요소에 적용 */
.tool span:nth-child(1){
  background-color: red;
  color:aliceblue;
}
.tool span:nth-child(2){
  background-color: blue;
  color: aliceblue;
}
.tool span:nth-child(3){
  background-color: orange;
}

.timer{
  /* 화면한가운데 위치 */
  position: fixed;
  top: 45%;
  right: 45%;
  width: 10%;
  height: 10%;
  /* background-color: #97ffa3; */
  z-index: 10000;
  display: flex;
  font-size: 10rem;
  font-weight: bolder;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: green;
}

.photo{
  /* display: flex;
  flex-direction: column;
  align-items: center
  justify-content: center; */
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: rgb(241, 225, 194);
  border: 1px solid black;
  padding: 1px 1px 1px 1px;
  margin: 0;
  border-radius: 5%;
  -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  -ms-border-radius: 5%;
  -o-border-radius: 5%;
  
}
.photo img{
  width: 100%;
  height: 100%;

} 

.imgcontainer{
  z-index: 10;
  width: 300px;
  height: 300px;
  /* background-attachment: fixed;
  background-color: #97ffa3;
  border: 1px solid black; */
}

.imgcontainer img{
  z-index: 11;
  width: 100%;
  height: 100%;
  border: 1px solid black;

}