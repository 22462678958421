.button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #0a7e23;
  border-color: aliceblue;
  color: white;
  /* padding: 0.5rem 1.5rem; */
  align-items: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.button:hover,
.button:active {
  background-color: #e90505;
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.5rem;
}

.badge {
  background-color: #b94517;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  font-weight: bold;
}

.button:hover .badge,
.button:active .badge {
  background-color: #92320c;
}

.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}