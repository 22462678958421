.meal {
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  width: auto;
}

.meal h3 {
  margin: 0 0 0.25rem 0;
}

.inputdate {
  font-style: italic;
  font-size: 0.75rem;
}

.price {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ad5502;
  font-size: 1.25rem;
}

.btnO{
  font: inherit;
  cursor: pointer;
  background-color: #e61919;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 12px;
  font-weight: bold;
}

.btnX{
  font: inherit;
  cursor: pointer;
  background-color: #311509;
  border: 1px solid #8a2b06;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 12px;
  font-weight: bold;
}


.btnO:hover,
.btnO:active {
  background-color: #f3eea2;
  color: black;
}
