.card {
  margin-top: 10px;
  padding: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow-y: scroll;
  height: 600px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}